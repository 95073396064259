import { FC } from "react";
import { ERC20Token } from "../../types";
import { TokenList } from "../token-list";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

interface Step3Props {
	tokens: ERC20Token[];
	selectedTokens?: ERC20Token[];
	handleTokenSelection: (token: ERC20Token) => void;
}

const Step3: FC<Step3Props> = ({ tokens, selectedTokens, handleTokenSelection }) => {

	return (
		<div id="slide3" className="carousel-item bg-black relative  w-full">
			<div className='h-screen w-full flex flex-col items-center justify-center p-5'>
				<label className="form-control w-full max-w-screen-md md:mt-0 -mt-32">
					<div className="label">
						<span className="label-text text-base font-light md:text-lg">
							What tokens do you accept?
						</span>
					</div>
					<TokenList tokens={tokens} all={true} selectedTokens={selectedTokens} handleTokenSelection={handleTokenSelection} />
					<div className="flex flex-row w-fill mt-3">
						<a
							type="button"
							href="#slide2"
							className="btn bg-black border-[#ff006e] text-[#ff006e] hover:bg-[#ff006e] hover:text-black hover:border-[#ff006e] btn-outline w-[144px] h-[64px] border-2 font-bold mr-auto  text-base rounded-lg  w-fit  text-base font-semibold   rounded-full sm:px-10  py-4 px-12 min-h-[unset] mr-auto h-[64px]"
						>
							<FaArrowLeft size="24" className=" " />
						</a>
						<a
							type="button"
							href="#slide4"
							className="btn btn-primary btn-outline w-[144px] h-[64px] hover:bg-[#ff006e] hover:text-black border-2 font-bold text-base rounded-lg text-white  w-fit  text-base font-semibold   rounded-full sm:px-10  py-4 px-12 min-h-[unset] ml-auto h-[64px]"
						>
							<FaArrowRight size="24" className=" " />
						</a>
					</div>
				</label>
			</div>
			<div className=" hidden absolute bottom-5 flex justify-between transform -translate-y-1/2 left-5 right-5 ">
				<a href="#slide2" className="btn btn-circle">❮</a>
				<a href="#slide4" className="btn btn-circle">❯</a>
			</div>
		</div>
	)
}

export default Step3