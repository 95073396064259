import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import "./styles/globals.css";
import { initFlowbite } from 'flowbite'
initFlowbite();

const activeChain = "ethereum";
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
	<ThirdwebProvider
		activeChain={activeChain}
		clientId={process.env.REACT_APP_TEMPLATE_CLIENT_ID}
	>
		<App />
	</ThirdwebProvider>
);
