import Create from "./pages/create-request";
import ConnectButton from "./components/button-connect";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "./pages/page-not-found";
import Logo from "./images/logo.png";
export default function App() {
	return (
		<>
			<BrowserRouter>
				<img src={Logo} alt="logo" className="absolute z-[9999] top-5 left-5 w-24 h-24" />
				<Routes>
					<Route path="/" element={<Create />} />
					<Route path="*" element={<PageNotFound />}></Route>
				</Routes>

				<div className="bottom-container bottom-0 left-0 w-full flex flex-col absolute">
					<ConnectButton />
				</div>

			</BrowserRouter>
		</>
	);
}
