import { ConnectWallet, darkTheme } from "@thirdweb-dev/react";

const ConnectButton = () => {
	return (
		<ConnectWallet theme={darkTheme({
			colors: {
				accentText: "#8338ec",
				accentButtonBg: "#8338ec",
				modalBg: "#000",
				borderColor: "#ffbe0b",
				separatorLine: "#000",
				danger: "#ff006e",
				success: "#8338ec",
				primaryText: "#fff",
				secondaryText: "#ffbe0b",
				accentButtonText: "#000",
				primaryButtonBg: "#000",
				primaryButtonText: "#fff",
				secondaryButtonHoverBg: "#ffbe0b",
				connectedButtonBg: "#000",
				connectedButtonBgHover: "#000",
				walletSelectorButtonHoverBg: "#000",
				secondaryIconColor: "#8338ec",
				selectedTextColor: "#8338ec",
				selectedTextBg: "#fff",
			},
		})}
			btnTitle={"Connect"}
			modalTitle={"Connect"}
			modalSize={"wide"}
			welcomeScreen={{ title: "Connect" }}
		/>
	)
}

export default ConnectButton;