import { useAddress } from "@thirdweb-dev/react";
import { useEffect, useState } from "react";
import { getERC20Tokens } from "../services/actions";
import Step1 from "../components/create-steps/step1";
import Step2 from "../components/create-steps/step2";
import Step3 from "../components/create-steps/step3";
import Step4 from "../components/create-steps/step4";
import { ERC20Token } from "../types";
const CreateRequest = () => {
	const connectedAddress = useAddress();
	const [address, setAddress] = useState<string>("");
	const [selectedTokens, setSelectedTokens] = useState<ERC20Token[]>([]);

	useEffect(() => {
		if (connectedAddress) setAddress(connectedAddress);
	}, [connectedAddress, address]);

	const handleTokenSelection = (token: ERC20Token) => {
		const index = selectedTokens.findIndex((t) => t.contractAddress === token.contractAddress);
		if (index === -1) {
			setSelectedTokens([...selectedTokens, token]);
		} else {
			const newTokens = selectedTokens.filter((t) => t.contractAddress !== token.contractAddress);
			setSelectedTokens(newTokens);
		}
	}

	return (
		<div className="carousel h-full w-full">
			<Step1 address={address} setAddress={setAddress} />
			<Step2 />
			<Step3 tokens={getERC20Tokens()} selectedTokens={selectedTokens} handleTokenSelection={handleTokenSelection} />
			<Step4 />
		</div>
	);
};
export default CreateRequest;
