import { ERC20Token } from "../types";

export function getERC20Tokens() {
	const cryptoTokens: ERC20Token[] = [
		{
			name: 'Ethereum',
			contractAddress: '0x1234567890abcdef',
			chain: 'Ethereum',
			symbol: 'ETH',
			decimals: 18,
			icon: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/32/color/eth.png'
		},
		{
			name: 'US Dollar Coin',
			contractAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
			chain: 'Ethereum',
			symbol: 'USDC',
			decimals: 6,
			icon: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/32/color/usdc.png'

		},
		{
			name: 'Tether',
			contractAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
			chain: 'Ethereum',
			symbol: 'USDT',
			decimals: 18,
			icon: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/32/color/usdt.png'

		},
	]
	return cryptoTokens;

}
