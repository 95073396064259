import { Dispatch, FC, SetStateAction } from "react";

interface Step4Props {

}

const Step4: FC<Step4Props> = ({ }) => {

	return (
		<div id="slide3" className="carousel-item bg-black relative  w-full">
			<div className='h-screen w-full flex items-center justify-center p-5'>
				<div className="dropdown btn-warning btn-outline w-[144px] h-[64px] border-2 border-warning  border-2 bg-black select-lg  ">
					<div tabIndex={0} role="button" className="btn m-1">Select accepted tokens</div>
					<ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
						<li>rrt</li>
						<li>ddsfv</li>
					</ul>
				</div>
			</div>
			<div className=" hidden absolute bottom-5 flex justify-between transform -translate-y-1/2 left-5 right-5 ">
				<a href="#slide2" className="btn btn-circle">❮</a>
				<a href="#slide4" className="btn btn-circle">❯</a>
			</div>
		</div>
	)
}

export default Step4