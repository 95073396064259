import { Dispatch, FC, SetStateAction } from "react";
import { FaArrowRight } from "react-icons/fa";

interface Step1Props {
	address?: string;
	setAddress: Dispatch<SetStateAction<string>>;
}

const Step1: FC<Step1Props> = ({ address, setAddress }) => {

	return (
		<div id="slide1" className="carousel-item duration-700 bg-black relative w-full">
			<div className="h-screen w-full flex items-center justify-center p-5">
				<label className="form-control w-full max-w-screen-md md:mt-0 -mt-32">
					<div className="label">
						<span className="label-text text-base font-light md:text-lg">
							What is your wallet address?
						</span>
					</div>
					<div className="flex flex-row  gap-3  w-full">

						<div className="w-full">
							<input
								type="text"
								value={address ? address : ""}
								onChange={(e) => setAddress(e.target.value)}
								placeholder="0x..."
								className="input text-white bg-black  h-[64px] font-semibold input-lg focus:border-[#ffbe0b]  border-2 border-[#ffbe0b] input-warning input-bordered w-full"
							/>
							<div className="label">
								<span className="label-text-alt text-xs font-light">
									The destination address for your request
								</span>
							</div>
						</div>

					</div>
					<a
						type="button"
						href="#slide2"
						className="btn btn-warning btn-outline w-[144px] h-[64px] border-2 font-bold text-base rounded-lg text-white  w-fit  text-base font-semibold   rounded-full sm:px-10  py-4 px-12 min-h-[unset] ml-auto h-[64px]"
					>
						<FaArrowRight size="24" className=" " />
					</a>

				</label>
			</div>
			<div className="absolute hidden bottom-5 flex justify-between transform -translate-y-1/2 left-5 right-5 ">
				<a id="goSlide2" href="#slide2" className="btn btn-circle">❯</a>
			</div>
		</div>
	)
}

export default Step1