import { FC } from "react";
import { ERC20Token } from "../types";
import { FaCheck } from "react-icons/fa";

interface TokenListProps {
	tokens: ERC20Token[];
	all: boolean;
	selectedTokens?: ERC20Token[];
	handleTokenSelection: (token: ERC20Token) => void;
}

export const TokenList: FC<TokenListProps> = ({ tokens, all, selectedTokens, handleTokenSelection }) => {
	return (
		<div className=" bg-black w-full border-2 border-[#8338ec] flex flex-col p-2 rounded-lg ">
			{tokens.map((t: ERC20Token, i: number) => (
				<button key={i} type="button" onClick={() => handleTokenSelection(t)} className="flex w-full items-center hover:bg-[#0d0d0d] rounded-lg gap-3 flex-row text-base text-white  font-medium p-4">
					<img src={t.icon} width="30" alt={t.symbol} />
					<span className="flex-1 text-left">{t.symbol} - {t.name}</span>
					<FaCheck className={`text-xl ${selectedTokens?.findIndex((st) => st.contractAddress === t.contractAddress) !== -1 ? "text-[#27ae60]" : "text-transparent"} transition-all`} />

				</button>
			))}
		</div>
	)
}