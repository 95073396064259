import { Link } from "react-router-dom";

const PageNotFound = () => {

	return (
		<div className="hero min-h-screen bg-base-200">
			<div className="hero-content text-center">
				<div className="max-w-md">
					<h1 className="text-5xl font-bold">404</h1>
					<p className="py-6">This page does not exist.</p>
					<Link to={"/"} className="btn btn-primary">Take me Home</Link>
				</div>
			</div>
		</div>
	)
}

export default PageNotFound;