import { Dispatch, FC, SetStateAction } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

interface Step2Props {

}

const Step2: FC<Step2Props> = ({ }) => {

	return (
		<div id="slide2" className="carousel-item bg-black relative  w-full">
			<div className="h-screen w-full flex items-center justify-center p-5">

				<label className="form-control w-full relative max-w-screen-md md:mt-0 -mt-32">

					<div className="label">
						<span className="label-text text-base font-light md:text-lg">
							How much money do you want to ask for back?
						</span>
					</div>
					<div className="flex  w-full gap-3 mb-3">
						<select className="select font-semibold text-white  btn-outline w-[144px] h-[64px] border-2 hover:bg-[#010101]  hover:border-[#ff006e] border-[#ff006e]  hover:text-white  border-2 bg-black select-lg  ">
							<option selected>$ USD</option>
							<option>€ EUR</option>
							<option>£ GBP</option>
						</select>
						<input
							type="number"
							placeholder="100.00"
							className="input text-white bg-black font-semibold focus:border-[#ff006e]  h-[64px]   input-lg border-2 border-[#ff006e] input-error input-bordered w-full"
						/>
					</div>
					<div className="flex flex-row w-full">
						<a
							type="button"
							href="#slide1"
							className="btn btn-warning btn-outline w-[144px] h-[64px]  border-2 font-bold mr-auto  text-base rounded-lg text-white  w-fit  text-base font-semibold   rounded-full sm:px-10  py-4 px-12 min-h-[unset] mr-auto h-[64px]"
						>
							<FaArrowLeft size="24" className=" " />

						</a>
						<a
							type="button"
							href="#slide3"
							className="btn bg-black border-[#ff006e]  w-[144px] h-[64px] border-2 hover:bg-[#ff006e] hover:border-[#ff006es] hover:text-black font-bold text-base rounded-lg text-[#ff006e]  w-fit  text-base font-semibold   rounded-full sm:px-10  py-4 px-12 min-h-[unset] ml-auto h-[64px]"
						>
							<FaArrowRight size="24" className=" " />
						</a>
					</div>
				</label>
			</div>
			<div className=" hidden absolute bottom-5 flex justify-between transform -translate-y-1/2 left-5 right-5 ">
				<a href="#slide1" className="btn btn-circle">❮</a>
				<a href="#slide3" className="btn btn-circle">❯</a>
			</div>
		</div>
	)
}

export default Step2